import { Pipe, PipeTransform } from '@angular/core';
import {getEquipmentNameFromSerial} from '../lookups/hardwareBrandsOrTypes';

@Pipe({
  name: 'serialToName'
})
export class SerialToNamePipe implements PipeTransform {

  transform(serial: string): string {
    return getEquipmentNameFromSerial(serial);
  }

}
