import { Pipe, PipeTransform } from '@angular/core';
import {getManualFilenameFromSerial} from '../lookups/hardwareBrandsOrTypes';

@Pipe({
  name: 'serialToManual'
})
export class SerialToManualPipe implements PipeTransform {

  transform(serial: string): string {
    return getManualFilenameFromSerial(serial);
  }

}
