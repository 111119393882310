import {Component, EventEmitter, OnInit, Output, Input, OnDestroy} from '@angular/core';
import {CustomerFeedbackService} from '../customer-feedback.service';
import {getCustomerFeedbackContactMethods, getCustomerFeedbackMainReasons,
  getCustomerFeedbackPartnerships, getCustomerFeedbackSpecificReasonsForMainReason, getCustomerFeedbackStatuses,
  getCustomerFeedbackTypes,
  SelectItemWithAddFilter} from '../../lookups/customerFeedback';
import {ConfirmationService, OverlayOptions, SelectItem} from 'primeng/api';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {getExternalEmailValidator, multiEmailValidator} from '../../validators/email.validator';
import {getWorkingDaysBetween} from '../../helpers/helperFunctions';
import moment from 'moment-timezone';
import {phoneNumberValidator} from '../../validators/phone-number.validator';
import {contactDetailsRequiredValidator} from '../../validators/contact-details-required.validator';
import {numberOnly, phoneNumberOnly} from '../../helpers/keyboardHelpers';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {getBrandBackgroundColour, getBrandColour, getWebsiteSelectItems} from '../../lookups/brands';
import {CustomerFeedback} from '../../models/customerFeedback.model';
import {environment} from '../../../environments/environment';
import {SelectItemWithEmail} from '../../models/selectItemWithEmail.model';
import {getManualSendEmailTypesForWebsite} from '../../lookups/manualSendMessageTypes';
import {Order} from '../../models/order.model';
import {AddNoteParams} from '../../models/addNoteParams.model';
import {OrderLockData} from '../../models/socket-io/orderLockData.model';
import {LocksSocketService} from '../../sockets/locks-socket.service';
import {OrderFormTag} from '../../models/orderFormTag.model';
import {DEFAULT_OVERLAY} from '../../lookups/primeng/overlayOptions';
import {notWhiteSpaceOnlyValidator} from '../../validators/not-whitespace.validator';

@Component({
  selector: 'app-add-customer-feedback[order][tags][closeModal]',
  templateUrl: './add-customer-feedback.component.html',
  styleUrls: ['./add-customer-feedback.component.scss'],
  providers: [ConfirmationService]
})
export class AddCustomerFeedbackComponent implements OnInit, OnDestroy {
  @Input() order: Order;
  @Input() tags: OrderFormTag[];
  @Output() closeModal: EventEmitter<AddNoteParams> = new EventEmitter<AddNoteParams>();
  feedbackTypes: SelectItemWithAddFilter<string>[];
  feedbackPartnerships: SelectItemWithEmail<string>[];
  feedbackMainReasons: SelectItemWithEmail<string>[];
  feedbackSpecificReasons: SelectItem<string>[];
  feedbackContactMethods: SelectItem<string>[];
  feedbackStatuses: SelectItem<string>[];
  websites: SelectItem<string>[];
  dialogVisible: boolean;
  customerFeedbackForm: UntypedFormGroup;
  addDisabled: boolean;
  emailRequired: boolean;
  resultMessage: string;
  minDateStr: string;
  escalateTo: SelectItemWithEmail<string>;
  orderLockList: OrderLockData[] = [];
  numberOnly = numberOnly;
  phoneNumberOnly = phoneNumberOnly;
  getBrandBackgroundColour = getBrandBackgroundColour;
  getBrandColour = getBrandColour;
  reviewTemplates: SelectItem<string>[];
  showSendEmailDialog: boolean;
  feedbackPriorities: SelectItem<string>[] = [{
    'label': 'Low',
    'value': 'Low'
  }, {
    'label': 'Medium',
    'value': 'Medium'
  }, {
    'label': 'High',
    'value': 'High'
  }];
  overlayOptions: OverlayOptions = DEFAULT_OVERLAY;

  constructor(
    private customerFeedbackService: CustomerFeedbackService,
    private formBuilder: UntypedFormBuilder,
    private confirmationService: ConfirmationService,
    private locksSocket: LocksSocketService,
  ) { }

  ngOnInit(): void {
    this.showSendEmailDialog = false;
    this.reviewTemplates = [];
    this.escalateTo = undefined;
    this.minDateStr = moment.tz('Europe/London').format('YYYY-MM-DD');
    this.feedbackTypes = getCustomerFeedbackTypes().filter((selectItem: SelectItemWithAddFilter<string>) =>
      selectItem.showOnAdd
    );
    this.feedbackPartnerships = getCustomerFeedbackPartnerships();
    this.feedbackMainReasons = getCustomerFeedbackMainReasons();
    this.feedbackSpecificReasons = [];
    this.feedbackContactMethods = getCustomerFeedbackContactMethods();
    this.feedbackStatuses = getCustomerFeedbackStatuses();
    this.websites = getWebsiteSelectItems();
    this.customerFeedbackForm = this.formBuilder.group({
      'websiteId': [null, Validators.required],
      'tdCode': [''],
      'orderId': [null],
      'loggedBy': [localStorage.getItem('userName')],
      'dateLogged': [(new Date()).toISOString()],
      'datePending': [null],
      'closedBy': [''],
      'dateClosed': [null],
      'feedbackType': ['', Validators.required],
      'priority': ['Medium', Validators.required],
      'partnership': ['', Validators.required],
      'status': ['Open', Validators.required],
      'resolutionDays': [null],
      'pendingDays': [null],
      'whoContactedUs': ['', notWhiteSpaceOnlyValidator],
      'relationshipToUser': ['', notWhiteSpaceOnlyValidator],
      'emailAddress': ['', getExternalEmailValidator(false)],
      'phoneNumber': ['', phoneNumberValidator],
      'contactMethod': ['', Validators.required],
      'mainReason': ['', Validators.required],
      'specificReason': ['', Validators.required],
      'feedbackDetails': ['', notWhiteSpaceOnlyValidator],
      'resolvedAtFirstContact': [false],
      'ccTo': ['', multiEmailValidator],
      'resolution': [''],
      'respondByEmail': [false],
      'freeServiceGiven': [false],
      'datePaymentRestarts': [{value: null, disabled: true}, Validators.required],
      'chaserEmailSent': [false],
    }, {'validators': contactDetailsRequiredValidator('Feedback Contact', 'emailAddress', 'phoneNumber')});
    if (this.order) {
      this.customerFeedbackForm.patchValue({
        'websiteId': this.order.website._id,
        'tdCode': this.order.alarmUserDetails.tdCode,
        'orderId': this.order._id,
      });
    }
    this.locksSocket.on('lockList', this.orderLockListUpdate);
    this.locksSocket.emit('getLocked');
    this.emailRequired = false;
    this.addDisabled = false;
    this.dialogVisible = true;
  }

  ngOnDestroy(): void {
    this.locksSocket.removeListener('lockList', this.orderLockListUpdate);
  }

  get isFreeServiceGiven(): boolean {
    return this.customerFeedbackForm.get('freeServiceGiven').value;
  }

  get isResolutionRequired(): boolean {
    return (['Closed'].includes(this.customerFeedbackForm.get('status').value) &&
      ['Complaint'].includes(this.customerFeedbackForm.get('feedbackType').value));
  }

  get isOkToAdd(): boolean {
    return this.customerFeedbackForm.valid && !this.addDisabled;
  }

  get isStatusReadOnly(): boolean {
    return (this.customerFeedbackForm.get('resolvedAtFirstContact').value ||
      !['Complaint'].includes(this.customerFeedbackForm.get('feedbackType').value));
  }

  orderLockListUpdate: (lockList: OrderLockData[]) => void = (orderLocks: OrderLockData[]) => {
    this.orderLockList = orderLocks;
  }

  isOrderLocked(_id: string): boolean {
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == _id).length > 0;
  }

  orderLockedBy(_id: string): string {
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == _id)[0].user;
  }

  changeFeedbackType(newType: string): void {
    if (!['Complaint'].includes(newType) && ('Closed' != this.customerFeedbackForm.get('status').value)) {
      this.customerFeedbackForm.patchValue({
        'status': 'Closed',
      });
      this.changeFeedbackStatus('Closed');
    }
  }

  changeMainReason(newReason: string): void {
    if (!newReason) {
      this.feedbackSpecificReasons = [];
    } else {
      this.feedbackSpecificReasons = getCustomerFeedbackSpecificReasonsForMainReason(newReason);
    }
    this.customerFeedbackForm.get('specificReason').setValue('');
  }

  changeFeedbackStatus(newStatus: string): void {
    if ('Pending' == newStatus) {
      const pendingDate: string = (new Date()).toISOString();
      const resolutionDays: Number = getWorkingDaysBetween(this.customerFeedbackForm.get('dateLogged').value, pendingDate);
      this.customerFeedbackForm.patchValue({
        'datePending': pendingDate,
        'closedBy': '',
        'dateClosed': null,
        'resolutionDays': resolutionDays,
        'pendingDays': null,
      });
    } else if ('Closed' == newStatus) {
      const closedDate: string = (new Date()).toISOString();
      this.customerFeedbackForm.patchValue({
        'dateClosed': closedDate,
        'closedBy': localStorage.getItem('userName'),
      });
      // Only update resolution days if not already set
      if (!this.customerFeedbackForm.get('resolutionDays').value) {
        const resolutionDays: Number = getWorkingDaysBetween(this.customerFeedbackForm.get('dateLogged').value, closedDate);
        this.customerFeedbackForm.patchValue({
          'resolutionDays': resolutionDays,
        });
      }
      // Only update pending days if the feedback had been to pending
      if (!this.customerFeedbackForm.get('datePending').value) {
        const pendingDays: Number = getWorkingDaysBetween(this.customerFeedbackForm.get('datePending').value, closedDate);
        this.customerFeedbackForm.patchValue({
          'pendingDays': pendingDays,
        });
      }
    } else {
      this.customerFeedbackForm.patchValue({
        'datePending': null,
        'closedBy': '',
        'dateClosed': null,
        'resolutionDays': null,
        'pendingDays': null,
      });
    }
    const resolutionControl: AbstractControl = this.customerFeedbackForm.get('resolution');
    if (this.isResolutionRequired) {
      resolutionControl.setValidators(notWhiteSpaceOnlyValidator);
    } else {
      resolutionControl.clearValidators();
    }
    resolutionControl.updateValueAndValidity();
  }

  changeResolvedAtFirstContact(event: Event): void {
    if ((event.target as HTMLInputElement).checked && (this.customerFeedbackForm.get('status').value != 'Closed')) {
      this.customerFeedbackForm.patchValue({
        'status': 'Closed',
      });
      this.changeFeedbackStatus('Closed');
    }
  }

  updateEmailAddressRequired(): void {
    const emailControl: AbstractControl = this.customerFeedbackForm.get('emailAddress');
    const respondByEmail: boolean = this.customerFeedbackForm.get('respondByEmail').value;
    const contactMethod: string = this.customerFeedbackForm.get('contactMethod').value;
    if (respondByEmail || ('Email' == contactMethod)) {
      this.emailRequired = true;
      emailControl.setValidators([Validators.required, getExternalEmailValidator(false)]);
    } else {
      this.emailRequired = false;
      emailControl.setValidators(getExternalEmailValidator(false));
    }
    emailControl.updateValueAndValidity();
  }

  addFeedback(): void {
    this.resultMessage = '';
    this.addDisabled = true;
    const newFeedback: CustomerFeedback = this.customerFeedbackForm.value;
    this.customerFeedbackService.saveNewCustomerFeedback({
      'feedback': newFeedback,
      'crmBaseUrl': `${environment.protocol}${environment.IPAddress}/`,
    }).subscribe((response: SimpleResponse) => {
      this.addDisabled = false;
      if (response.success) {
        // If sucess, but with error then some emails failed to send
        if (response.error) {
          this.showErrorPopUp('Error Sending Emails', response.error, () => {
            this.sendReviewEmailIfRequiredThenClose();
          });
        } else {
          this.sendReviewEmailIfRequiredThenClose();
        }
      } else {
        this.resultMessage = `Error adding Customer Feedback. Error: ${response.message}`;
      }
    }, (err: Error) => {
      this.addDisabled = false;
      this.resultMessage = `Error adding Customer Feedback. Error: ${err.message}`;
    });
  }

  sendReviewEmailIfRequiredThenClose(): void {
    if (!this.order) {
      this.closeModal.emit();
      return;
    }
    const feedback: CustomerFeedback = this.customerFeedbackForm.value;
    let noteCategory: string = 'General';
    switch (feedback.feedbackType) {
      case 'Complaint':
        noteCategory = 'Complaints'
        break;
      case 'Compliment':
        noteCategory = 'Compliment'
        break;
      case 'Dissatisfaction':
        noteCategory = 'Dissatisfaction'
        break;
      default:
        break;
    }
    let noteText: string = `${feedback.loggedBy} logged a ${feedback.feedbackType} about ${feedback.mainReason} - ${feedback.specificReason}\n` +
        `Details: ${feedback.feedbackDetails}`;
    if (feedback.resolution) {
      noteText = `${noteText}\nResolution:${feedback.resolution}`;
    }
    if ('Compliment' != feedback.feedbackType) {
      this.closeModal.emit({
        'content': noteText,
        'categories': [noteCategory],
      });
      return;
    }
    this.reviewTemplates = getManualSendEmailTypesForWebsite(this.order.website._id).filter((template: SelectItem<string>) =>
      'Reviews Invite' == template.label
    );
    if (this.reviewTemplates.length == 0) {
      this.closeModal.emit({
        'content': noteText,
        'categories': [noteCategory],
      });
      return;
    }
    this.showQuestionPopup('Review Request', 'Would you like to email the customer to leave a review?',
      () => {this.showSendEmailDialog = true;}, () => {this.closeModal.emit({
        'content': noteText,
        'categories': [noteCategory],
      });}
    );
  }

  closeSendEmailDialog(): void {
    this.reviewTemplates = [];
    this.showSendEmailDialog = false;
    this.closeModal.emit();
  }

  closeDialog(): void {
    this.closeModal.emit();
  }

  freeServiceChanged(): void {
    if (this.isFreeServiceGiven) {
      this.customerFeedbackForm.get('datePaymentRestarts').enable();
    } else {
      this.customerFeedbackForm.get('datePaymentRestarts').disable();
    }
  }

  showQuestionPopup(header: string, message: string, acceptCallback?: () => void, rejectCallback?: () => void): void {
    this.showConfirmationPopup('general', header, message, 'pi pi-question-circle', acceptCallback, rejectCallback);
  }

  showInfoPopUp(header: string, message: string, acceptCallback?: () => void, rejectCallback?: () => void): void {
    this.showConfirmationPopup('general', header, message, 'pi pi-info-circle', acceptCallback, rejectCallback);
  }

  showErrorPopUp(header: string, message: string, acceptCallback?: () => void, rejectCallback?: () => void): void {
    this.showConfirmationPopup('error', header, message, 'pi pi-exclamation-triangle', acceptCallback, rejectCallback);
  }

  showConfirmationPopup(key: string, header: string, message: string, icon: string,
      acceptCallback?: () => void, rejectCallback?: () => void, isRejectLabelVisisble: boolean = true) {
    let acceptClicked: boolean = false;
    this.confirmationService.confirm({
      'key': key,
      'header': header,
      'message': message,
      'rejectVisible': isRejectLabelVisisble,
      'icon': icon,
      accept: () => {
        acceptClicked = true;
        if (acceptCallback) {
          acceptCallback();
        }
      },
      reject: () => {
        if (rejectCallback && !acceptClicked) {
          rejectCallback();
        }
      }
    });
  }
}
