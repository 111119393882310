import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

const WHITESPACE_ONLY_REGEX: RegExp = /^\s*$/;

export const notWhiteSpaceOnlyValidator: ValidatorFn = (field: AbstractControl): ValidationErrors|null => {
  if (WHITESPACE_ONLY_REGEX.test(field.value)) {
    return {'notWhiteSpaceOnly': field.value};
  }
  return null;
}
