<p-dialog  [(visible)]="display" [modal]="true" [style]="{width: '850px', height: '800px' , minWidth: '600px'}" [minY]="700"
           [maximizable]="true" [baseZIndex]="10000">
  <div class="container" *ngIf="progress>-1" >
    <h3>Update in progress...</h3>
    <p-progressBar [value]="progressVal"></p-progressBar>
  </div>
  <p-footer  *ngIf="progress>-1">
    <button type="button" pButton icon="pi pi-check" (click)="loadSiteProducts()" label="Refresh"></button>
  </p-footer>
</p-dialog>

<div class="container">
  <div class="d-flex justify-content-center m-1">
    <p-dropdown [options]="brands" [overlayOptions]="overlayOptions" [(ngModel)]="selectedBrand" placeholder="Brands" scrollHeight="600px">
      <ng-template let-Pbrand let-i="index" pTemplate="item">
        <span class="brandDropDown" [ngStyle]="{'background-color': brandConfigs[Pbrand.value].background, 'color': brandConfigs[Pbrand.value].color}">
          &nbsp;{{Pbrand.value}}
        </span>
      </ng-template>
    </p-dropdown>
    <div class="ml-2">
      <button type="button" class="btn btn-primary" pButton (click)="loadSiteProducts()" label="Load Products" [disabled]="!selectedBrand"></button>
    </div>
  </div>
</div>
<ng-container *ngIf='selectedWebsite && (selectedWebsite.usingWebBackend === "WooCommerce")'>
  <div class="tableFixHead">
    <table border="1">
      <thead>
        <tr>
          <th></th>
          <th>SKU</th>
          <th>Product title</th>
          <th>CRM title</th>
          <th>Brand</th>
          <th>Pull in<br/>orders</th>
          <th>Display on<br/>records</th>
          <th>Display on<br/>CSEs page</th>
          <th>Product Type</th>
          <th>Hardware / Hardware Sets<br/>/ Services</th>
          <th>Product<br/>Image</th>
          <th>VAT</th>
          <th>Renewal<br/>Price</th>
          <th>Plan<br/>Symbols</th>
          <th>Renewal After Months</th>
          <th>Regular Price</th>
          <th>
            Status <br/>
            <p-multiSelect [options]="statuses" [(ngModel)]="selectedStatuses" placeholder="Statuses" filterPlaceHolder="status"
              [panelStyle]="{minWidth:'12em'}" scrollHeight="600px" [maxSelectedLabels]="0" selectedItemsLabel="{0} selected"
              [overlayOptions]="overlayOptions">
            </p-multiSelect>
          </th>
        </tr>
      </thead>
      <ng-container *ngFor="let product of products; let i = index">
        <tbody *ngIf="(selectedStatuses.length === 0) || (selectedStatuses.includes(product.status))"
            [ngStyle]="{'background-color': selectedWebsite.background + '80'}">
          <tr>
            <td [attr.data-target]="'#' +product.sku" >{{i+1}}</td>
            <td> {{product.sku}} </td>
            <td>
              <a href="{{product.url}}">
                <span *ngIf="!!product.isSub"> --&gt; </span> {{product.title}} 
              </a>
              <div *ngIf="product.subProducts.length > 0">(COMPOSITE)</div>
              <div *ngIf="product.isSub">(SUBPRODUCT)</div>
            </td>
            <td><input [(ngModel)]="product.crmTitle" type="text"></td>
            <td>
              <a href="/websites/{{product.websiteId._id}}">{{product.websiteId.title}}</a>
            </td>
            <td>
              <select [(ngModel)]="product.pullinOrders" class="yesNo">
                <option selected value></option>
                <option value="yes">YES</option>
                <option value="no">No</option>
              </select>
            </td>
            <td>
              <select [(ngModel)]="product.displayOnRecords" class="yesNo">
                <option selected value></option>
                <option value="yes">YES</option>
                <option value="no">No</option>
              </select>
            </td>
            <td>
              <select [(ngModel)]="product.displayOnCSES" class="yesNo">
                <option selected value></option>
                <option value="yes">YES</option>
                <option value="no">No</option>
              </select>
            </td>
            <td>
              <p-dropdown [options]="wooProductCategories" [(ngModel)]="product.category" [required]=true [overlayOptions]="overlayOptions"
                placeholder="Product Type" styleClass="category" (onChange)="setCategory(product)" >
              </p-dropdown>
            </td>
            <td>
              <ng-container *ngIf="(product.variations.length === 0) && (!['keySafe', 'services'].includes(product.category))">
                <label>Hardware Sets:</label>
                <ng-container *ngFor="let hardwareSet of product.hardwareSets; let i = index">
                  <div>
                    {{hardwareSet.title}}
                    <button type="button" class="btn icon-btn" (click)="deleteHardwareSet(product, i)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <hr />
                </ng-container>
                <div>
                  <p-dropdown styleClass="addHardware" [options]="hardwareSetList" placeholder="Select hardware set" [overlayOptions]="overlayOptions" #addSetDD>
                  </p-dropdown>
                  <button type="button" class="btn icon-btn" (click)="addHardwareSet(product, addSetDD.value)">
                    <i class="fas fa-plus-square"></i>
                  </button>
                </div>
                <hr/>
                <label>Hardware:</label>
                <ng-container *ngFor="let hardware of product.hardware; let i = index">
                  <div>
                    {{hardware.title}}
                    <button type="button" class="btn icon-btn" (click)="deleteHardware(product, i)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <hr />
                </ng-container>
                <div>
                  <p-dropdown styleClass="addHardware" [options]="hardwareList" placeholder="Select hardware" [overlayOptions]="overlayOptions" #addHardwareDD>
                  </p-dropdown>
                  <button type="button" class="btn icon-btn" (click)="addHardware(product, addHardwareDD.value)">
                    <i class="fas fa-plus-square"></i>
                  </button>
                </div>
              </ng-container>
              <ng-container *ngIf="(product.category === 'services')">
                <label>Service:</label>
                <div>
                  <p-dropdown styleClass="addHardware" [options]="accountServicesList" placeholder="Select Service" [(ngModel)]="product.serviceId"
                    [overlayOptions]="overlayOptions">
                  </p-dropdown>
                </div>
              </ng-container>
            </td>
            <td><img *ngIf="product.productImage!=='false'" src="{{product.productImage}}">
              <p *ngIf="product.productImage==='false'">No Image</p>
            </td>
            <td>
              <select *ngIf="(product.subProducts.length === 0) && (product.variations.length === 0) && (product.category !== 'keySafe')"
                  [(ngModel)]="product.vat" class="vat">
                <option disabled selected value></option>
                <option *ngFor="let vatStatus of vatStatuses" [value]="vatStatus.value">
                  {{vatStatus.label}}
                </option>
              </select>
              <input *ngIf="product.category === 'keySafe'" [(ngModel)]="product.vat" type="text" readonly class="vat"/>
            </td>
            <td>
              <input *ngIf="(product.subProducts.length === 0) && (product.variations.length === 0) && (product.category !== 'keySafe')"
                  type="number" step="0.01" [(ngModel)]="product.renewalPrice">
            </td>
            <td>
              <input *ngIf="(product.subProducts.length === 0) && (product.variations.length === 0) && (product.category !== 'keySafe')" 
                  type="text" [(ngModel)]="product.planOrEquipment" class="plan">
            </td>
            <td>
              <select *ngIf="(product.category !== 'keySafe') && (!product.isSub)" [(ngModel)]="product.renewalAfterDate">
                <option value=''></option>
                <option value=3>3</option>
                <option value=12>12</option>
              </select>
            </td>
            <td>
              <input *ngIf="(product.subProducts.length === 0) && (product.variations.length === 0)" type="number" step="0.01"
                  [(ngModel)]="product.regularPrice">
            </td>
            <td>
              <select [(ngModel)]="product.status" class="status">
                <option *ngFor="let productStatus of productStatusOptions" [value]="productStatus.value">
                  {{productStatus.label}}
                </option>
              </select>
            </td>
          </tr>
          <tr class="variations" *ngFor="let variation of product.variations; let j = index">
            <td>{{i+1}} : {{j+1}}</td>
            <td>{{variation.sku}}</td>
            <td colspan="7">
              <div *ngFor="let attribute of variation.attributes; let k = index" >
                {{attribute.name}}: {{attribute.option}};
                - Sku: <input type="text"  [(ngModel)]="variation.attributes[k].sku">
              </div>
            </td>
            <td>
              <ng-container *ngIf="(product.category !== 'keySafe')">
                <label>Hardware Sets:</label>
                <ng-container *ngFor="let hardwareSet of variation.hardwareSets; let i = index">
                  <div>
                    {{hardwareSet.title}}
                    <button type="button" class="btn icon-btn" (click)="deleteHardwareSet(variation, i)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <hr />
                </ng-container>
                <div>
                  <p-dropdown styleClass="addHardware" [options]="hardwareSetList" placeholder="Select hardware set" [overlayOptions]="overlayOptions" #addSetDD>
                  </p-dropdown>
                  <button type="button" class="btn icon-btn" (click)="addHardwareSet(variation, addSetDD.value)">
                    <i class="fas fa-plus-square"></i>
                  </button>
                </div>
                <hr/>
                <label>Hardware:</label>
                <ng-container *ngFor="let hardware of variation.hardware; let i = index">
                  <div>
                    {{hardware.title}}
                    <button type="button" class="btn icon-btn" (click)="deleteHardware(variation, i)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <hr />
                </ng-container>
                <div>
                  <p-dropdown styleClass="addHardware" [options]="hardwareList" placeholder="Select hardware" [overlayOptions]="overlayOptions" #addHardwareDD>
                  </p-dropdown>
                  <button type="button" class="btn icon-btn" (click)="addHardware(variation, addHardwareDD.value)">
                    <i class="fas fa-plus-square"></i>
                  </button>
                </div>
              </ng-container>
            </td>
            <td></td>
            <td>
              <input *ngIf="(product.category === 'keySafe')" [(ngModel)]="variation.vat" type="text" class="vat" readonly />
              <select *ngIf="(product.category !== 'keySafe')" [(ngModel)]="variation.vat" class="vat">
                <option disabled selected value></option>
                <option *ngFor="let vatStatus of vatStatuses" [value]="vatStatus.value">
                  {{vatStatus.label}}
                </option>
              </select>
            </td>
            <td>
              <input *ngIf="(product.category !== 'keySafe')" type="number" step="0.01" [(ngModel)]="variation.renewalPrice">
            </td>
            <td>
              <input *ngIf="(product.category !== 'keySafe')" type="text" [(ngModel)]="variation.symbol" class="plan">
            </td>
            <td>
              <select [(ngModel)]="variation.renewalAfterDate">
                <option value=''></option>
                <option value=3>3</option>
                <option value=12>12</option>
              </select>
            </td>
            <td><input type="number" [(ngModel)]="variation.regular_price"></td>
            <td></td>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </div>
  <div>
    <button type="button" class="btn-success"
        style="position: fixed; right: 17px; bottom: 38px; color: #fff; background-color: #17a2b8; border-color: #000000; padding: 8px 73px;"
        (click)="allUpdate()">UPDATE ALL</button>
  </div>
</ng-container>
<ng-container *ngIf='selectedWebsite && (selectedWebsite.usingWebBackend === "Shopify")'>
  <div class='container'>
    <h2>Refresh Products</h2>
    <div>
      <label for="skus">Product Ids to Import/Refresh (one id per line)</label>
      <textarea [(ngModel)]="ids" rows="5" id="ids"  class="form-control">
      </textarea>
      <div class="mt-1 mb-2">
        <button type="button" class="btn btn-primary" (click)="refreshProducts()" [disabled]="isRefreshProductsDisabled">
          Refresh Products
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngIf='showRefreshResponse'>
    <div class='container'>
      <p-table #productRefreshTable [value]="refreshResponses" [columns]="refreshColumns" [paginator]="true" [rows]="15"
        [sortOrder]="1" sortMode="single" sortField="identifier" [showCurrentPageReport]="true" tableStyleClass="shopifyTable"
        currentPageReportTemplate="Page {currentPage} of {totalPages} - Product Updates {first} to {last} of {totalRecords}">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon>
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns">
              <input pInputText type="search" (input)="applyProductRefreshFilter($event, col.field, 'startsWith')" />
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">
              {{rowData[col.field]}}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
  <hr/>
  <div id='shopifyProductsContainer'>
    <p-table #shopifyProductTable [value]="shopifyProducts" [columns]="shopifyProductsColumns" [paginator]="true" [rows]="15"
        [sortOrder]="1" sortMode="single" sortField="crmProductTitle" [showCurrentPageReport]="true" tableStyleClass="shopifyTable"
        currentPageReportTemplate="Page {currentPage} of {totalPages} - Products {first} to {last} of {totalRecords}">
      <ng-template pTemplate="header" let-columns>
        <tr class='shopifyProductHeader'>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <ng-container [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="'shopifyVariants'"></ng-container>
              <ng-container *ngSwitchDefault>
                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order">
                </p-sortIcon>
              </ng-container>
            </ng-container>
          </th>
        </tr>
        <tr class='shopifyProductHeader'>
          <th *ngFor="let col of columns">
            <ng-container [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="'shopifyVariants'">
                <div class="row">
                  <div class="col-4">
                    <input pInputText type="search" (input)="applyVariantFilter($event, col.field, 'array-subfield-contains', ['shopifyVariantId'])" placeholder='Variant Id' />
                  </div>
                  <div class="col-8">
                    <input pInputText type="search" (input)="applyVariantFilter($event, col.field, 'array-subfield-contains', ['crmVariantTitle'])" placeholder='Variant Title'/>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'updatedAt'">
                <p-multiSelect [options]="statuses" placeholder="Statuses" filterPlaceHolder="status"
                  scrollHeight="600px" [maxSelectedLabels]="0" selectedItemsLabel="{0} selected"
                  (onChange)="shopifyProductTable.filter($event.value,'status','in')" [overlayOptions]="overlayOptions">
                </p-multiSelect>
              </ng-container>
              <ng-container *ngSwitchCase="'tags'">
                <p-multiSelect [options]="shopifyTags" placeholder="Tags" filterPlaceHolder="Tag"
                  [panelStyle]="{minWidth:'12em'}" scrollHeight="600px" [maxSelectedLabels]="0" selectedItemsLabel="{0} selected"
                  (onChange)="shopifyProductTable.filter($event.value,col.field,'array-contains-array')" [overlayOptions]="overlayOptions">
                </p-multiSelect>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <input pInputText type="search" (input)="applyProductFilter($event, col.field, 'contains')" />
              </ng-container>
            </ng-container>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns" [ngSwitch]="col['field']">
            <ng-container *ngSwitchCase="'shopifyProductId'">
              {{rowData.shopifyProductId | shopifyId}}
            </ng-container>
            <ng-container *ngSwitchCase="'crmProductTitle'">
              <input [(ngModel)]="rowData[col.field]" type="text" [required]=true>
              <label>Shopify title:</label> {{rowData.productTitle}}<br/>
            </ng-container>
            <ng-container *ngSwitchCase="'displayOnCseOrderPage'">
              <ng-container *ngIf="isValidForOrderPage(rowData.crmCategory); else notValidForOrderPage">
                <p-dropdown [options]="yesNoOptions" [(ngModel)]="rowData[col.field]" [required]=true [overlayOptions]="overlayOptions">
                </p-dropdown>
              </ng-container>
              <ng-template #notValidForOrderPage>
                {{rowData[col.field]}}
              </ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="'crmCategory'">
              {{rowData.crmCategory}}<br/>
              <label>Shopify Collections:</label>
              <div *ngFor='let collection of rowData.shopifyCollectionNames'>
                {{collection}}
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'tags'">
              <div *ngFor='let tag of rowData.tags'>
                {{tag}}
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'shopifyVariants'">
              <ng-container *ngIf='rowData.isBundle; else notBundle'>
                <table class='bundleTable'>
                  <thead>
                    <tr>
                      <th>SKU / Title</th>
                      <th>Taxable</th>
                      <th>Basket Price</th>
                      <th>Rental Period</th>
                      <th>Bundle Contents</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let variant of rowData.shopifyVariants; let variantIdx = index">
                      <td>
                        {{variant.sku}}<br/>
                        <input [(ngModel)]="variant.crmVariantTitle" type="text" [required]=true>
                        <label>Shopify title:</label> {{variant.variantDisplayName}}<br/>
                        <label>Variant Id:</label> {{variant.shopifyVariantId | shopifyId}}<br/>
                      </td>
                      <td>{{variant.taxable? 'Not Exempt': 'Exempt'}}</td>
                      <td>
                        <ng-container *ngIf='variant.taxable; else nonTaxableBasket'>
                          <label>Ex VAT:</label> {{variant.basketPrice / exToIncVatMultiplier | currency :currencyCode:'symbol':'1.2-2'}}
                          <br/>
                          <label>Inc VAT:</label>
                          <input [(ngModel)]="variant.basketPrice" type="number" step="0.01" min="0.01" (keypress)="numberOnly($event, true)"
                            [required]=true [readOnly]="!isValidForPriceChange(rowData.crmCategory)">
                        </ng-container>
                        <ng-template #nonTaxableBasket>
                          <label>Ex VAT:</label>
                          <input [(ngModel)]="variant.basketPrice" type="number" step="0.01" min="0.01" (keypress)="numberOnly($event, true)"
                            [required]=true [readOnly]="!isValidForPriceChange(rowData.crmCategory)">
                        </ng-template>
                      </td>
                      <td>
                        {{variant.rentalPeriod}}
                      </td>
                      <td>
                        <div *ngFor='let pvc of variant.productVariantComponents'>
                          <strong>{{pvc.productVariant.product.title}}</strong>&nbsp;*&nbsp;{{pvc.quantity}}<br/>
                          (Variant Id: {{pvc.productVariant.id | shopifyId}})
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
              <ng-template #notBundle>
                <table class='variantTable'>
                  <thead>
                    <tr>
                      <th>SKU / Title</th>
                      <th>Taxable</th>
                      <th>Basket Price</th>
                      <th>Hardware</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let variant of rowData.shopifyVariants; let variantIdx = index">
                      <td>
                        {{variant.sku}}<br/>
                        <input [(ngModel)]="variant.crmVariantTitle" type="text" [required]=true>
                        <label>Shopify title:</label> {{variant.variantDisplayName}}<br/>
                        <label>Variant Id:</label> {{variant.shopifyVariantId | shopifyId}}<br/>
                      </td>
                      <td>{{variant.taxable? 'Not Exempt': 'Exempt'}}</td>
                      <td>
                        <ng-container *ngIf='variant.taxable; else nonTaxableBasket'>
                          <label>Ex VAT:</label> {{variant.basketPrice / exToIncVatMultiplier | currency :currencyCode:'symbol':'1.2-2'}}
                          <br/>
                          <label>Inc VAT:</label>
                          <input [(ngModel)]="variant.basketPrice" type="number" step="0.01" min="0.01" (keypress)="numberOnly($event, true)"
                            [required]=true [readOnly]="!isValidForPriceChange(rowData.crmCategory)">
                        </ng-container>
                        <ng-template #nonTaxableBasket>
                          <label>Ex VAT:</label>
                          <input [(ngModel)]="variant.basketPrice" type="number" step="0.01" min="0.01" (keypress)="numberOnly($event, true)"
                            [required]=true [readOnly]="!isValidForPriceChange(rowData.crmCategory)">
                        </ng-template>
                      </td>
                      <td>
                        <ng-container [ngSwitch]="rowData | shopifyProductToType">
                          <ng-container *ngSwitchCase="'Additional Equipment'">
                            <p-dropdown [options]="shopifyAccessoryList" placeholder="Select hardware" [overlayOptions]="overlayOptions"
                              [(ngModel)]="variant.hardware">
                            </p-dropdown>
                          </ng-container>
                          <ng-container *ngSwitchCase="'Base Unit'">
                            <p-dropdown [options]="shopifyBaseUnitList" placeholder="Select hardware" [overlayOptions]="overlayOptions"
                              [(ngModel)]="variant.hardware">
                            </p-dropdown>
                          </ng-container>
                          <ng-container *ngSwitchCase="'GPS Alarm'">
                            <p-dropdown [options]="shopifyGpsList" placeholder="Select hardware" [overlayOptions]="overlayOptions"
                              [(ngModel)]="variant.hardware">
                            </p-dropdown>
                          </ng-container>
                          <ng-container *ngSwitchCase="'Cables/Chargers'">
                            <div>Cables/Chargers - nothing to add to order record</div>
                          </ng-container>
                          <ng-container *ngSwitchCase="'Key Safes'">
                            <div>Key safes use the product's title</div>
                          </ng-container>
                          <ng-container *ngSwitchCase="'Setup Fees'">
                            <div>Setup fee, no physical product</div>
                          </ng-container>
                          <ng-container *ngSwitchCase="'Subscriptions'">
                            <div>Subscription, no physical product</div>
                          </ng-container>
                          <ng-container *ngSwitchCase="'Installations'">
                            <div>Installation, no physical product</div>
                          </ng-container>
                        </ng-container>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="'updatedAt'">
              <p-dropdown [options]="productStatusOptions" [(ngModel)]="rowData['status']" [required]=true [overlayOptions]="overlayOptions">
              </p-dropdown>
              <div>
                <button type="button" class="btn btn-primary my-1" (click)="saveShopifyProduct(rowData)">
                  Save
                </button>
              </div>
              {{rowData.updatedAt | date:'dd/MM/y HH:mm'}}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{rowData[col.field]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-container>
<p-confirmDialog key="general"></p-confirmDialog>
<p-toast></p-toast>
