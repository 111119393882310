import {SelectItem} from 'primeng/api';
import {EX_TO_INC_VAT_MULTIPLIER, sortByLabel} from '../helpers/helperFunctions';
import {PaymentDetails} from '../models/paymentDetails.model';

interface EquipmentSerialLookup {
  'description': string;
  'comment'?: string;
  'serialRegex': string;
  'prices': {[brandCode: string]: number};
  'manualFile': string;
}

let equipmentSerialLookups: EquipmentSerialLookup[];

function getEquipmentSerialLookup(): EquipmentSerialLookup[] {
  if (!equipmentSerialLookups) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('equipment: serial lookup')) {
      return [];
    }
    equipmentSerialLookups = JSON.parse(localStorage.getItem('equipment: serial lookup')) || [];
  }
  return equipmentSerialLookups;
}

function getEquipmentPriceAndDescription(serial: string, brandCode: string, addVat: boolean): PaymentDetails {
  const paymentDetails: PaymentDetails = {
    'paymentFor': '',
    'paymentAmount': '',
  };
  if (!serial) {
    return paymentDetails;
  }
  const equipLookups: EquipmentSerialLookup[] = getEquipmentSerialLookup();
  const upperSerial: string = serial.toLocaleUpperCase();
  const matchingLookup: EquipmentSerialLookup = equipLookups.find((equipLookup: EquipmentSerialLookup) => {
    const serialRegex: RegExp = new RegExp(equipLookup.serialRegex);
    return (serialRegex.test(upperSerial));
  });
  if (matchingLookup && matchingLookup.prices[brandCode]) {
    paymentDetails.paymentFor = matchingLookup.description;
    let amount: number = Number(matchingLookup.prices[brandCode]);
    if (addVat) {
      amount *= EX_TO_INC_VAT_MULTIPLIER;
    }
    paymentDetails.paymentAmount = amount.toFixed(2);
  }
  return paymentDetails;
}

export {
  getEquipmentPriceAndDescription,
}