
<div class="container">
  <hr>
  <p class="h1">Coupons</p>
  <hr>
  <ul class="list-group">
    <li  *ngFor="let website of websites"  class="list-group-item d-flex justify-content-between align-items-center">
      <a routerLink="/coupons/{{website._id}}">{{website.title}}</a>
      <ng-container *ngIf='website.usingWebBackend === "WooCommerce"'>
        <button type="button" class="btn btn-primary " (click)="updateCoupons(website._id)">
          Update coupons
        </button>
      </ng-container>
    </li>
  </ul>
  <hr>
</div>
<div class="container-fluid">
  <h1>{{webId}}</h1>
  <ng-container *ngIf='selectedWebsite && (selectedWebsite.usingWebBackend === "WooCommerce")'>
    <p-table #couponTable [value]="coupons" [columns]="couponColumns" [paginator]="true" [rows]="20"
        [sortOrder]="1" sortMode="single" sortField="code" [showCurrentPageReport]="true"
        currentPageReportTemplate="Page {currentPage} of {totalPages} - Coupons {first} to {last} of {totalRecords}">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                ariaLabelDesc="Activate to sort in descending order"
                ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of columns">
            <input pInputText type="search" (input)="applyCouponFilter($event, col.field, 'contains')" />
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            {{rowData[col.field]}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
  <ng-container *ngIf='selectedWebsite && (selectedWebsite.usingWebBackend === "Shopify")'>
    <h2>Refresh Discounts</h2>
    <div>
      <label for="skus">Discount Codes to Import/Refresh (one code per line)</label>
      <textarea [(ngModel)]="codes" rows="5" id="codes"  class="form-control">
      </textarea>
      <div class="mt-1 mb-2">
        <button type="button" class="btn btn-primary" (click)="refreshDiscounts()" [disabled]="codes.trim() === ''">
          Refresh Discounts
        </button>
      </div>
    </div>
    <ng-container *ngIf='showRefreshResponse'>
      <p-table #discountRefreshTable [value]="refreshResponses" [columns]="refreshColumns" [paginator]="true" [rows]="15"
        [sortOrder]="1" sortMode="single" sortField="identifier" [showCurrentPageReport]="true"
        currentPageReportTemplate="Page {currentPage} of {totalPages} - Coupons {first} to {last} of {totalRecords}">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon>
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns">
              <input pInputText type="search" (input)="applyDiscountRefreshFilter($event, col.field, 'startsWith')" />
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">
              {{rowData[col.field]}}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-container>
    <hr/>
    <p-table #discountTable [value]="shopifyDiscounts" [columns]="discountColumns" [paginator]="true" [rows]="15"
        [sortOrder]="1" sortMode="single" sortField="code" [showCurrentPageReport]="true"
        currentPageReportTemplate="Page {currentPage} of {totalPages} - Discounts {first} to {last} of {totalRecords}">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                ariaLabelDesc="Activate to sort in descending order"
                ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of columns">
            <input pInputText type="search" (input)="applyDiscountFilter($event, col.field, 'contains')" />
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns" [ngSwitch]="col['field']">
            <ng-container *ngSwitchCase="'oncePerOrder'">
              {{rowData[col.field]}}<br/>
              <label>Shopify ID:</label><br/>{{rowData.shopifyDiscountId | shopifyId}}
            </ng-container>
            <ng-container *ngSwitchCase="'oncePerOrder'">
              {{rowData[col.field]? 'Yes': 'No'}}
            </ng-container>
            <ng-container *ngSwitchCase="'startDate'">
              {{rowData[col.field] | date:'dd/MM/y HH:mm'}}
            </ng-container>
            <ng-container *ngSwitchCase="'expiryDate'">
              {{rowData[col.field] | date:'dd/MM/y HH:mm'}}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{rowData[col.field]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
</div>
<p-confirmDialog key="general"></p-confirmDialog>
