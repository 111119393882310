import {Component, OnInit, ViewChild} from '@angular/core';
import {LogsService} from "../logs/logs.service";
import {environment} from "../../../environments/environment";
import {PageCountResponse} from '../../models/responses/pageCountResponse.model';
import {MessageService, SelectItem} from 'primeng/api';
import moment from 'moment-timezone';
import {BrandCfg, getBrandCodes, getBrandConfigs, getBrandSelectItems} from '../../lookups/brands';
import {Table} from 'primeng/table';
import {Column} from '../../models/column.model';
import {Title} from '@angular/platform-browser';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {Log} from '../../models/log.model';

@Component({
  selector: 'app-import-log',
  templateUrl: './import-log.component.html',
  styleUrls: ['./import-log.component.scss'],
  providers: [MessageService],
})
export class ImportLogComponent implements OnInit {
  logs: Log[];
  cols: Column[];
  API_URL = environment.protocol+environment.IPAddress+'/api';
  minDateStr: string;
  maxDateStr: string;
  defaultStartDateStr: string;
  logsLoading: boolean = false;
  logsPagesToLoad: number = 0;
  sourcesToLoadPageNumbersFor: number = 0;
  filterMinDate: Date;
  filterMaxDate: Date;
  filterYearRange: string;
  dateFilters: Date[];
  brands: SelectItem<string>[];
  brandConfigs: BrandCfg;
  selectedBrands: string[];
  @ViewChild('tt', {static: false})
  logTable: Table;
  objectKeys: (o: {}) => string[];

  constructor(
    private logsService: LogsService,
    private messageService: MessageService,
    private title: Title,
  ) { }

  ngOnInit() {
    this.brandConfigs = getBrandConfigs();
    this.brands = getBrandSelectItems();

    this.title.setTitle('CRM Import Logs');

    this.objectKeys = Object.keys;
    this.logs= [];

    this.cols = [
      {field: 'job', header: 'Job No.'},
      {field: 'source', header: 'Action'},
      {field: 'user', header: 'Staff member'},
      {field: 'date', header: 'Date / Time'},
      {field: 'fields', header: 'Fields'},
      {field: 'nbRecorders', header: 'Total Rows'},
      {field: 'recorderPerBrand', header: 'Brands'},
      {field: 'recorderPerBrand', header: 'Records Per Brand'},
      {field: 'fileName', header: 'Attachment'},
    ];

    this.minDateStr = moment.tz('Europe/London').subtract(12, 'months').format('YYYY-MM-DD');
    this.defaultStartDateStr = moment.tz('Europe/London').subtract(1, 'months').format('YYYY-MM-DD');
    this.maxDateStr = moment.tz('Europe/London').format('YYYY-MM-DD');

    this.selectedBrands = getBrandCodes();
  }

  showPageCountError(errorMessage: string) {
    this.messageService.add({
      severity: 'error',
      life: 300000,
      summary: 'Something went wrong!',
      detail: 'Error getting page count' + errorMessage,
    });
  }

  loadImportLogs(startDate: string, endDate: string) {
    this.logsLoading = true;
    this.logs = [];
    this.logsPagesToLoad = 0;
    this.filterMinDate = new Date(startDate);
    this.filterMaxDate = new Date(endDate);
    this.filterYearRange = `${this.filterMinDate.getFullYear()}:${this.filterMaxDate.getFullYear()}`;
    this.sourcesToLoadPageNumbersFor = 2;
    this.loadImportLog('upload', startDate, endDate);
    this.loadImportLog('Export', startDate, endDate);
  }

  loadImportLog(source: string, startDate: string, endDate: string) {
    this.logsService.getLogPageCount(source, startDate, endDate).subscribe(
      (response: PageCountResponse) => {
        if (!response.success) {
          this.logsLoading = !((this.logsPagesToLoad == 0) && (this.sourcesToLoadPageNumbersFor == 0));
          this.showPageCountError(response.message);
        } else {
          this.logsPagesToLoad = this.logsPagesToLoad + response.pageCount!;
          if ((this.logsPagesToLoad == 0) && (this.sourcesToLoadPageNumbersFor == 0)) {
            this.logsLoading = false;
          }
          for (let page: number = 1; page <= response.pageCount!; page++) {
            this.loadPage(source, startDate, endDate, page);
          }
        }
      }
    );
  }

  loadPage(source: string, startDate: string, endDate: string, page: number) {
    this.logsService
      .getLogPage(source, startDate, endDate, page)
      .subscribe((response: MultiRecordResponse<Log>) => {
        this.logs = this.logs.concat(response.data!);
        this.logsPagesToLoad--;
        if ((this.logsPagesToLoad == 0) && (this.sourcesToLoadPageNumbersFor == 0)) {
          this.logsLoading = false;
        }
      });
  }

  getFileExtension(filename: string): string {
    if (!!filename) {
      return filename.split('.').pop();
    }
    return '';
  }

  globalFilter($event: Event, filterType: string): void {
    this.logTable.filterGlobal(($event.target as HTMLInputElement).value, filterType);
  }

  applyFilter($event: Event, field: string, filterType: string): void {
    this.logTable.filter(($event.target as HTMLInputElement).value, field, filterType);
  }
}
