import {BusinessGrowthContact, BusinessGrowthCustomer, BusinessGrowthEquipment} from '../../models/businessGrowthCustomer.model';
import {BusinessGrowthService} from '../business-growth.service';
import {phoneNumberValidator, mobileNumberValidator, formatPhoneNumberInE164Format} from '../../validators/phone-number.validator';
import {getExternalEmailValidator} from '../../validators/email.validator';
import {Tag} from '../../models/tag.model';
import {TagsResponse} from '../../models/responses/tagsResponse.model';
import {TagsService} from '../../setup/tags/tags.service';
import {UntypedFormBuilder, UntypedFormArray, Validators, UntypedFormGroup} from '@angular/forms';
import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {numberOnly, phoneNumberOnly} from '../../helpers/keyboardHelpers';
import {Address, AddressWithRole} from '../../models/address.model';
import getAddressClient, {FindFailed, FindSuccess, Result} from 'getaddress-api';
import {environment} from '../../../environments/environment';
import {getLookupFromGetAddressResult} from '../../helpers/getAddressHelper';
import {getBusinessGrowthAutomatedMessageOptions} from '../../lookups/businessGrowth';
import {DropDownChangeEvent} from '../../models/primeng/dropdownChangeEvent.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {notWhiteSpaceOnlyValidator} from '../../validators/not-whitespace.validator';

@Component({
  selector: 'app-business-growth-customer[openMode][businessGrowthCustomer][closeBGCustomer]',
  templateUrl: './business-growth-customer.component.html',
  styleUrls: ['./business-growth-customer.component.scss'],
  providers: [],
})
export class BusinessGrowthCustomerComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tagService: TagsService,
    private businessGrowthService: BusinessGrowthService,
  ) { }

  @Input() openMode: string;
  @Input() businessGrowthCustomer: BusinessGrowthCustomer;
  @Output() closeBGCustomer: EventEmitter<string> = new EventEmitter<string>();
  displayModal: boolean = true;
  tags: SelectItem<Tag>[] = [];
  duplicateError: string = null;
  disableSubmit: boolean = false;
  loading: boolean = true;
  numberOnly = numberOnly;
  phoneNumberOnly = phoneNumberOnly;
  bgcForm: UntypedFormGroup = this.formBuilder.group({
    bgcName: ['', [notWhiteSpaceOnlyValidator]],
    bgcIdentifyingTag: [''],
    bgcAdditionalTags: [[]],
    additionalServices: [''],
    bgcAddresses: this.formBuilder.array([]),
    bgcContacts: this.formBuilder.array([]),
    bgcEquipment: this.formBuilder.array([]),
    bgcCouponCode: [''],
    allowedAutomatedMesssages: [''],
  });
  getAddrClient: getAddressClient;
  searchPostcodes: {[index: number]: string} = {};
  searchError: {[index: number]: string} = {};
  addressResults: {[index: number]: SelectItem<Address>[]} = {};
  allowAddressManualEntry: {[index: number]: boolean} = {};
  businessGrowthAutomatedMessageOptions: SelectItem<string>[];
  tagRequired: boolean;
  EMPTY_ADDRESS: AddressWithRole = {
    'addressOne': '',
    'addressTwo': '',
    'city': '',
    'county': '',
    'postcode': '',
    'validated': false,
    'role': ''
  };

  ngOnInit(): void {
    this.getAddrClient = new getAddressClient(environment.getAddressDomainToken);
    this.getTags();
    this.businessGrowthAutomatedMessageOptions = getBusinessGrowthAutomatedMessageOptions();
    this.tagRequired = false;

    if (this.openMode == 'add') {
      this.addContactsToForm({});
      this.addAddressesToForm(this.EMPTY_ADDRESS);
    } else if (this.openMode == 'edit') {
      this.getBusinessGrowthCustomer();
    } else {
      this.setFormValues(this.businessGrowthCustomer);
    }
  }

  get contactForms(): UntypedFormArray {
    return this.bgcForm.get('bgcContacts') as UntypedFormArray;
  }

  get addressForms(): UntypedFormArray {
    return this.bgcForm.get('bgcAddresses') as UntypedFormArray;
  }

  get equipmentForms(): UntypedFormArray {
    return this.bgcForm.get('bgcEquipment') as UntypedFormArray;
  }

  addBusinessGrowthCustomer() {
    this.disableSubmit = true;
    this.duplicateError = '';
    const body: BusinessGrowthCustomer = {
      ...this.bgcForm.value
    };
    body.bgcName = body?.bgcName?.trim();
    // if (!this.bgcForm.value.bgcIdentifyingTag) {
    //   delete body.bgcIdentifyingTag;
    // }
    this.businessGrowthService.addBusinessGrowthCustomer(body).subscribe({
      'next': (response: SingleRecordResponse<BusinessGrowthCustomer>) => {
        this.disableSubmit = false;
        if (response.success) {
          this.closeBGCustomer.emit('success')
        } else if (!response.success && (response?.error?.code == 11000)) {
          if (response.error.keyPattern?.bgcName) {
            this.duplicateError = 'Name already in use, please choose another';
          } else if (response.error.keyPattern?.bgcIdentifyingTag) {
            this.duplicateError = 'Tag already in use, please choose another';
          } else if (response.error.keyPattern?.bgcCouponCode) {
            this.duplicateError = 'Coupon Code already in use, please choose another';
          } else {
            this.duplicateError = response.error.message;
          }
        } else {
          this.closeBGCustomer.emit('error');
        }
      },
      'error': (err: Error) => {
        console.error("Error on adding Business Growth Customer: ", err);
        this.closeBGCustomer.emit('error');
      }
    });
  }

  updateBusinessGrowthCustomer() {
    this.duplicateError = '';
    this.disableSubmit = true;
    const body: BusinessGrowthCustomer = {
      ...this.bgcForm.value
    };
    body.bgcName = body?.bgcName?.trim();
    // if (!this.bgcForm.value.bgcIdentifyingTag) {
    //   delete body.bgcIdentifyingTag;
    // }
    this.businessGrowthService.updateBusinessGrowthCustomer(this.businessGrowthCustomer._id, body).subscribe({
      'next': (response: SingleRecordResponse<BusinessGrowthCustomer>) => {
        this.disableSubmit = false;
        if (response.success) {
          this.closeBGCustomer.emit('success');
        } else if (!response.success && (response?.error?.code == 11000)) {
          if (response.error.keyPattern?.bgcName) {
            this.duplicateError = 'Name already in use, please choose another';
          } else if (response.error.keyPattern?.bgcIdentifyingTag) {
            this.duplicateError = 'Tag already in use, please choose another';
          } else if (response.error.keyPattern?.bgcCouponCode) {
            this.duplicateError = 'Coupon Code already in use, please choose another';
          } else {
            this.duplicateError = response.error.message;
          }
        } else {
          this.closeBGCustomer.emit('error');
        }
      },
      'error': (err: Error) => {
        console.error('Error on update Business Growth Customer: ', err);
        this.closeBGCustomer.emit('error');
      }
    });
  }

  setFormValues(bgCustomer: BusinessGrowthCustomer) {
    this.bgcForm.patchValue({
      bgcName: bgCustomer?.bgcName,
      bgcIdentifyingTag: bgCustomer?.bgcIdentifyingTag,
      bgcAdditionalTags: bgCustomer?.bgcAdditionalTags,
      additionalServices: bgCustomer?.additionalServices,
      bgcCouponCode: bgCustomer?.bgcCouponCode,
      allowedAutomatedMesssages: bgCustomer?.allowedAutomatedMesssages,
    });
    bgCustomer.bgcContacts.forEach((contact: BusinessGrowthContact) => {
      this.addContactsToForm(contact);
    });
    bgCustomer.bgcAddresses.forEach((address: AddressWithRole) => {
      this.addAddressesToForm(address);
    });
    bgCustomer.bgcEquipment.forEach((equipment: BusinessGrowthEquipment) => {
      this.addEquipmentToForm(equipment);
    });
    this.tagRequired = !!bgCustomer.bgcCouponCode;
  }

  addAddressesToForm(address: AddressWithRole) {
    const addressForm: UntypedFormGroup = this.formBuilder.group({
      'addressOne': [address?.addressOne],
      'addressTwo': [address?.addressTwo],
      'city': [address?.city],
      'county': [address?.county],
      'postcode': [address?.postcode],
      'role': [address?.role],
      'validated': [address?.validated],
    });
    this.addressForms.push(addressForm);
  }

  addContactsToForm(contact: BusinessGrowthContact) {
    const contactForm: UntypedFormGroup = this.formBuilder.group({
      'firstName': [contact?.firstName],
      'lastName': [contact?.lastName],
      'telephone': [contact?.telephone, [phoneNumberValidator]],
      'mobile': [contact?.mobile, [mobileNumberValidator]],
      'email': [contact?.email, [getExternalEmailValidator(false)]],
      'role': [contact?.role]
    });
    this.contactForms.push(contactForm);
  }

  addEquipmentToForm(equipment: BusinessGrowthEquipment) {
    const equipmentForm: UntypedFormGroup = this.formBuilder.group({
      equipmentName: [equipment.equipmentName],
      quantity: [equipment.quantity],
      price: [equipment.price],
    });
    this.equipmentForms.push(equipmentForm);
  }

  getBusinessGrowthCustomer() {
    this.businessGrowthService.getBusinessGrowthCustomer(this.businessGrowthCustomer._id).subscribe({
        'next': (response: SingleRecordResponse<BusinessGrowthCustomer>) => {
        if (response.success) {
          this.businessGrowthCustomer = response.data;
          this.setFormValues(this.businessGrowthCustomer);
        } else {
          this.setFormValues(this.businessGrowthCustomer);
        }
        this.loading = false;
      },
      'error': (err: Error) => {
        console.error('Error on update Business Growth Customer: ', err);
        this.closeBGCustomer.emit('error');
      }
    })
  }

  getTags() {
    this.tagService.getActiveTags().subscribe({
      'next': (response: TagsResponse) => {
        this.tags = response.tags.map((tag: Tag) => {
          return {
            label: tag.tagName,
            value: tag
          }
        });
      }
    });
  }

  deleteContact(contactIndex: number) {
    this.contactForms.removeAt(contactIndex);
  }

  deleteAddress(addressIndex: number) {
    for (let idx: number = 0; idx < this.addressForms.length - 1; idx++) {
      this.searchPostcodes[idx] = this.searchPostcodes[idx+1];
      this.searchError[idx] = this.searchError[idx+1];
      this.addressResults[idx] = this.addressResults[idx+1];
      this.allowAddressManualEntry[idx] = this.allowAddressManualEntry[idx+1];
    }
    delete this.searchPostcodes[this.addressForms.length - 1];
    delete this.searchError[this.addressForms.length - 1];
    delete this.addressResults[this.addressForms.length - 1];
    delete this.allowAddressManualEntry[this.addressForms.length - 1];
    this.addressForms.removeAt(addressIndex);
  }

  deleteEquipment(equipmentIndex: number) {
    this.equipmentForms.removeAt(equipmentIndex);
  }

  onCouponCodeChange(): void {
    const couponCode: string = this.bgcForm.value.bgcCouponCode.trim();
    if (couponCode) {
      if (couponCode != couponCode.toLocaleLowerCase()) {
        this.bgcForm.patchValue({
          bgcCouponCode: couponCode.toLocaleLowerCase(),
        });
      }
      this.tagRequired = true;
    } else {
      this.tagRequired = false;
    }
  }

  closeDialog() {
    this.closeBGCustomer.emit('close');
  }

  addressSearch(addressIndex: number): void {
    if (!this.searchPostcodes[addressIndex]) {
      return;
    }
    this.addressResults[addressIndex] = [];
    this.searchError[addressIndex] = '';
    this.allowAddressManualEntry[addressIndex] = false;
    this.getAddrClient.find(this.searchPostcodes[addressIndex]).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.addressResults[addressIndex] = getLookupFromGetAddressResult(this.searchPostcodes[addressIndex], addressResult);
      if (!addressResult.isSuccess) {
        this.searchError[addressIndex] = addressResult.toFailed().message;
        console.error('Business Growth Customer Address search failed. Error:', this.searchError[addressIndex]);
      } else if (this.addressResults[addressIndex].length <= 2) {
        this.searchError[addressIndex] = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Business Growth Customer Address search failed. Error:', error);
    });
  }

  setAddress(event: DropDownChangeEvent<Address>, addressIndex: number): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowAddressManualEntry[addressIndex] = true;
      const existingAddress: Address = this.addressForms.at(addressIndex).value;
      let postcodeToUse: string = existingAddress.postcode;
      if (!existingAddress.addressOne && !existingAddress.postcode) {
        postcodeToUse = this.searchPostcodes[addressIndex];
      }
      this.addressForms.at(addressIndex).patchValue({
        'postcode': postcodeToUse,
        'validated': false,
      });
      return;
    }
    this.allowAddressManualEntry[addressIndex] = false;
    this.addressForms.at(addressIndex).patchValue({
      'addressOne': selectedAddress.addressOne,
      'addressTwo': selectedAddress.addressTwo,
      'city': selectedAddress.city,
      'county': selectedAddress.county,
      'postcode': selectedAddress.postcode,
      'validated': selectedAddress.validated,
    });
  }
}
